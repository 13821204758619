const Config = {
    // 测试环境
    development: {
        baseUrl: "http://172.16.200.116:9180",
        imageUrl: "https://res2-cdn-bbs.longtugame.com/",
        imageHost: "res2-cdn-bbs.longtugame.com",
        mallSwiperClassItemId: {
            dy: 61,
            mt: 59,
            r3d: 247,
            yxwd: 253
        }
    },
    // 沙盒环境
    sandbox: {
        baseUrl: "https://bbsinterface.longtugame.cn",
        imageUrl: "https://res2-cdn-bbs.longtugame.com/",
        imageHost: "res2-cdn-bbs.longtugame.com",
        mallSwiperClassItemId: {
            dy: 97,
            mt: 89,
            r3d: 151,
            yxwd: 177
        }
    },
    // 生产环境
    production: {
        baseUrl: "https://bbsinterface.longtugame.cn",
        imageUrl: "https://res2-cdn-bbs.longtugame.com/",
        imageHost: "res2-cdn-bbs.longtugame.com",
        mallSwiperClassItemId: {
            dy: 97,
            mt: 89,
            r3d: 151,
            yxwd: 177
        },
        hmId: {
            dy: 'f521ef0b446d691c7b4489511564837c',
            mt: '6245f992be1ef4d344227c4328751cd3',
            r3d: '93adb6f38bb87d00f95c73c959f714b2',
            yxwd: '5e3fe884696ab5b21fe467af16a2dc71'
        }
    },
    // 生产环境 海外
    production_global: {
        baseUrl: "https://bbsinterface-usa.eggtartgame.com",
        imageUrl: "https://res2-cdn-bbs.eggtartgame.com/",
        imageHost: "res2-cdn-bbs.eggtartgame.com",
        mallSwiperClassItemId: {
            z: 223,
            sh: 245
        },
        hmId: {
            sh: 'f521ef0b446d691c7b4489511564837c',
            z: '6245f992be1ef4d344227c4328751cd3',
        }
    },
    // 沙盒环境 海外
    sandbox_global: {
        baseUrl: "https://bbsinterface-usa.eggtartgame.com",
        imageUrl: "https://res2-cdn-bbs.eggtartgame.com/",
        imageHost: "res2-cdn-bbs.eggtartgame.com",
        mallSwiperClassItemId: {
            z: 223,
            sh: 245
        },
        hmId: {
            sh: 'f521ef0b446d691c7b4489511564837c',
            z: '6245f992be1ef4d344227c4328751cd3',
        }
    }
};

export default Config