import {createStore} from 'redux'
import config from '../config'
import { PullStatus } from 'antd-mobile/es/components/pull-to-refresh'

export interface IState {
    refreshStatusDict: Record<PullStatus, string>
    productInfoDict: Record<string, any>
    productSetting: Record<string, any>
    userInfo: Record<string, any>
    pageCacheHome: Record<string, any>
    forwardOriginalInfo: string
    popTextRef: any
    popConfirmRef: any
}
export interface IAction {
    type: string
    data: any
}

console.log(process.env.HTTP_ENV)
const initialState: IState = {
    // 下拉刷新国际化文案配置项
    refreshStatusDict: {
        pulling: 'common.下拉刷新',
        canRelease: 'common.释放立即刷新',
        refreshing: 'common.加载中...',
        complete: 'common.刷新成功'
    },
    // 产品信息字典
    productInfoDict: {
        dy: { productId: '20000050', localeId: '01', className: 'custom-dy', homeRecommendId: 65, mallSwiperClassItemId: (config[process.env.HTTP_ENV as string].mallSwiperClassItemId as any).dy },
        mt: { productId: '20000062', localeId: '01', className: 'custom-mt', homeRecommendId: 41, mallSwiperClassItemId: (config[process.env.HTTP_ENV as string].mallSwiperClassItemId as any).mt },
        r3d: { productId: '20000052', localeId: '01', className: 'custom-mt', homeRecommendId: 123, mallSwiperClassItemId: (config[process.env.HTTP_ENV as string].mallSwiperClassItemId as any).r3d },
        yxwd: { productId: '20000036', localeId: '01', className: 'custom-yxwd', homeRecommendId: 133, mallSwiperClassItemId: (config[process.env.HTTP_ENV as string].mallSwiperClassItemId as any).yxwd },
        z: { productId: '20000083', localeId: '12', className: 'custom-warz', homeRecommendId: 159, mallSwiperClassItemId: (config[process.env.HTTP_ENV as string].mallSwiperClassItemId as any).z },
        sh: { productId: '20000050', localeId: '12', className: 'custom-dy', homeRecommendId: 169, mallSwiperClassItemId: (config[process.env.HTTP_ENV as string].mallSwiperClassItemId as any).sh },
    },
    productSetting: {},// 产品配置
    userInfo: {},// 用户信息
    pageCacheHome: {},// 首页缓存
    forwardOriginalInfo: '',// 转发信息
    popTextRef: null,// 文字提示框
    popConfirmRef: null// 确定提示框
}
const Reducer = (state = initialState, action: IAction) => {
    switch(action.type) {
        // 产品配置
        case "PRODUCTSETTING":
            return {...state, productSetting: action.data}
        // 用户信息
        case "USERINFO":
            return {...state, userInfo: action.data}
        // 首页数据缓存
        case "PAGECACHEHOME":
            return {...state, pageCacheHome: action.data}
        // 滚动位置
        case "PAGECACHEHOMESCROLL":
            const pageCacheHomeScroll = Object.entries(state.pageCacheHome).map(([k, v]: any[]) => {
                if (+k === action.data.classification) {
                    v.scrollTop = action.data.scrollTop
                }
                return [k, v]
            }).reduce((p, v) => ({ ...p, [v[0]]: v[1] }), {})
            return {...state, pageCacheHome: pageCacheHomeScroll}
        // 收藏/取消收藏
        case "PAGECACHEHOMECOLLECT":
            const pageCacheHomeCollect = Object.entries(state.pageCacheHome).map(([k, v]: any[]) => {
                if (v.topicList) {
                    const topicList = v.topicList.map((i: Record<string, any>) => {
                        return i.id === action.data.id ? { ...i, collected: action.data.value } : i
                    })
                    return [k, {...v, topicList}]
                } else {
                    return [k, v]
                }
            }).reduce((p, v) => ({ ...p, [v[0]]: v[1] }), {})
            return {...state, pageCacheHome: pageCacheHomeCollect}
        // 删除
        case "PAGECACHEHOMEDELETE":
            const pageCacheHomeDelete = Object.entries(state.pageCacheHome).map(([k, v]: any[]) => {
                if (v.topicList) {
                    const topicList = v.topicList.map((i: Record<string, any>) => {
                        return i.id === action.data ? { ...i, isDelete: true } : i
                    })
                    return [k, {...v, topicList}]
                } else {
                    return [k, v]
                }
            }).reduce((p, v) => ({ ...p, [v[0]]: v[1] }), {})
            return {...state, pageCacheHome: pageCacheHomeDelete}
        // 转发缓存
        case "FORWARFORIGINALINFO":
            return {...state, forwardOriginalInfo: action.data}
        // 文字提示框
        case "POPTEXTREF":
            return {...state, popTextRef: action.data}
        // 确定提示框
        case "POPCONFIRMREF":
            return {...state, popConfirmRef: action.data}
        default:
            return state
    }
}

const store = createStore(Reducer)

export default store