import zh from './translations/zh-cn.json'
import tw from './translations/zh-tw.json'
import en from './translations/en-us.json'
import ru from './translations/ru.json'
import de from './translations/de.json'
import es from './translations/es.json'
import fr from './translations/fr.json'
import id from './translations/id.json'
import it from './translations/it.json'
import ja from './translations/ja.json'
import ko from './translations/ko.json'
import nl from './translations/nl.json'
import no from './translations/no.json'
import pl from './translations/pl.json'
import pt from './translations/pt.json'
import sv from './translations/sv.json'
import th from './translations/th.json'
import tr from './translations/tr.json'
import vi from './translations/vi.json'

const resources = {
    zh: { translation: zh },
    en: { translation: en },
    'zh-TW': { translation: tw },
    ru: { translation: ru },
    de: { translation: de },
    es: { translation: es },
    fr: { translation: fr },
    id: { translation: id },
    it: { translation: it },
    ja: { translation: ja },
    ko: { translation: ko },
    nl: { translation: nl },
    no: { translation: no },
    pl: { translation: pl },
    pt: { translation: pt },
    sv: { translation: sv },
    th: { translation: th },
    tr: { translation: tr },
    vi: { translation: vi }
}

export default resources;