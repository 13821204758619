import React, { Suspense } from 'react'
import { lazy } from 'react'
import { Routes, Route, Navigate, useParams } from 'react-router-dom'
import { IRoute } from './types'

// 导航页
const App = lazy(() => import('../App'))
// 首页
const Home = lazy(() => import('../container/home'))
// 话题
const Gambit = lazy(() => import('../container/gambit'))
// 详情
const Detail = lazy(() => import('../container/detail'))
// 搜索
const Search = lazy(() => import('../container/search'))
// 搜索结果
const SearchResult = lazy(() => import('../container/search/searchResult'))
// 百科
const Encyclopedia = lazy(() => import('../container/encyclopedia'))
// 发布
const Publish = lazy(() => import('../container/publish'))
// 发布选择话题
const PublishGambit = lazy(() => import('../container/publish/components/gambit'))
// 个人中心
const UserCenter = lazy(() => import('../container/mine'))
// 修改个人信息
const UserInfo = lazy(() => import('../container/mine/edit'))
// 修改头像
const EditAvatar = lazy(() => import('../container/mine/components/editAvatar'))
// 修改昵称
const EditUserName = lazy(() => import('../container/mine/components/editUserName'))
// 修改个性签名
const EditSignature = lazy(() => import('../container/mine/components/editSignature'))
// 绑定设置
const AccountSetting = lazy(() => import('../container/mine/components/accountSetting'))
// 消息
const Message = lazy(() => import('../container/message'))
// 积分
const Integral = lazy(() => import('../container/integral'))
// 积分商城
const IntegralMall = lazy(() => import('../container/integral-mall'))
// 兑换历史
const ExchangeHistory = lazy(() => import('../container/integral-mall/components/exchangeHistory'))
// 礼包
const GiftBag = lazy(() => import('../container/gift-bag'))
// 举报
const Report = lazy(() => import('../container/report'))
// 三方登录重定向
const Callback = lazy(() => import('../components/callback'))
// 推广重定向
const AdRedirect = lazy(() => import('../components/adRedirect'))
// 游戏内打开重定向
const GameinnerRedirect = lazy(() => import('../components/gameinnerRedirect'))

// 路由配置
const routes: Array<IRoute> = [
    { path: '/:product', name: 'app', element: <App />, children: [
        { path: '/:product/home', name: 'home', element: <Home /> },
        { path: '/:product/gambit', name: 'gambit', element: <Gambit /> },
        { path: '/:product/detail', name: 'detail', element: <Detail /> },
        { path: '/:product/search', name: 'search', element: <Search /> },
        { path: '/:product/searchResult', name: 'searchResult', element: <SearchResult /> },
        { path: '/:product/encyclopedia', name: 'encyclopedia', element: <Encyclopedia /> },
        { path: '/:product/publish', name: 'publish', element: <Publish /> },
        { path: '/:product/publish-gambit', name: 'publish-gambit', element: <PublishGambit /> },
        { path: '/:product/userCenter', name: 'userCenter', element: <UserCenter /> },
        { path: '/:product/userInfo', name: 'userInfo', element: <UserInfo /> },
        { path: '/:product/userInfo/avatar', name: 'editAvatar', element: <EditAvatar /> },
        { path: '/:product/userInfo/userName', name: 'editUserName', element: <EditUserName /> },
        { path: '/:product/userInfo/signature', name: 'editSignature', element: <EditSignature /> },
        { path: '/:product/userInfo/accountSetting', name: 'accountSetting', element: <AccountSetting /> },
        { path: '/:product/message', name: 'message', element: <Message /> },
        { path: '/:product/integral', name: 'integral', element: <Integral /> },
        { path: '/:product/integral-mall', name: 'integralMall', element: <IntegralMall /> },
        { path: '/:product/exchange-history', name: 'exchangeHistory', element: <ExchangeHistory /> },
        { path: '/:product/gift-bag', name: 'giftBag', element: <GiftBag /> },
        { path: '/:product/report', name: 'report', element: <Report /> },
        { path: '/:product/ad', name: 'ad', element: <AdRedirect /> },
        { path: '/:product/gameinner', name: 'gameinner', element: <GameinnerRedirect /> },
    ] },
    { path: '/callback/:target', name: 'thirdLoginCallback', element: <Callback /> }
]


const AppRedirect = () => {
    const routeParams = useParams()
    return <Navigate to={`/${routeParams.product}/home`} />
}

const ViewRouter = () => {
    return (
        <Suspense fallback={<></>}>
            <Routes>
                {
                    routes.map((item, index) => {
                        return (
                            <Route key={index} path={item.path} element={item.element}>
                                {
                                    item.children?.length && item.children.map(child => {
                                        return <Route key={item.name} path={child.path} element={child.element}></Route>
                                    })
                                }
                            </Route>
                        )
                    })
                }
                <Route path="/:product/" element={ <AppRedirect/> } />
                <Route path="/:product/home/:classId/" element={ <AppRedirect/> } />
            </Routes>
        </Suspense>
    )
}

export default ViewRouter