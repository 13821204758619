import React from 'react'
import { createRoot } from 'react-dom/client'
import { HashRouter } from 'react-router-dom'
import { HelmetProvider } from 'react-helmet-async';
import config from './config'
import store from './store'
import ViewRouter from './router'
import { Provider } from 'react-redux'
import i18n from 'i18next'
import './i18n'
import './style/style.less'
import './style/custom/style.cumtom.less'
import './style/custom/component.custom.less'
import './style/custom/publish.custom.less'
import './style/mt.less'
import './style/yxwd.less'
import './style/warz.less'
import './style/dy.less'
import './style/component.less'

window.GLOBALCONFIG = {
    APIHOST: config[process.env.HTTP_ENV as string]
}

// 根据域名跳转到对应产品
const hostnamePrefix = window.location.hostname.split('-')[0]
if (['dy', 'mt', 'r3d', 'yxwd', 'z'].indexOf(hostnamePrefix) !== -1) {
    window.location.href = window.location.href.replace('index.html', `#/${hostnamePrefix}`)
}

const paramStr = window.location.hash.split('?')[1]
if (paramStr) {
    let paramList = paramStr.split('&')
    let params: Record<string, string> = {}
    paramList.map(item => {
        return params[item.split('=')[0]] = item.split('=')[1]
    })
    const productSign = window.location.hash.split('/')[1]
    if (params.bbsrandom) {
        // 记录当前产品bbsrandom
        const productInfo = JSON.parse(localStorage.getItem('productInfo') || '{}')
        productInfo[productSign] = Object.assign(productInfo[productSign] || {}, {
            bbsrandom: params.bbsrandom
        })
        localStorage.setItem('productInfo', JSON.stringify(productInfo))
    }
    if (params.bbstokenid) {
        // 记录当前产品bbstokenid
        const productInfo = JSON.parse(localStorage.getItem('productInfo') || '{}')
        productInfo[productSign] = Object.assign(productInfo[productSign] || {}, {
            bbstokenid: params.bbstokenid
        })
        localStorage.setItem('productInfo', JSON.stringify(productInfo))
    }
    const defaultLanguage = localStorage.getItem('i18nextLng') || 'en'
    const language = params.olanguage || defaultLanguage
    i18n.changeLanguage(language === 'zh-CN' ? 'zh-TW' : language)
    localStorage.setItem('i18nextLng', language === 'zh-CN' ? 'zh-TW' : language)
} else {
    i18n.changeLanguage((localStorage.getItem('i18nextLng') === 'zh-CN' ? 'zh-TW' : localStorage.getItem('i18nextLng')) || 'en')
    localStorage.setItem('i18nextLng', (localStorage.getItem('i18nextLng') === 'zh-CN' ? 'zh-TW' : localStorage.getItem('i18nextLng')) || 'en')
}

createRoot(document.getElementById('root')).render(
    <HelmetProvider>
        <React.StrictMode>
            <HashRouter>
                <Provider store={store}>
                    {/* 启用页面路由 */}
                    <ViewRouter></ViewRouter>
                </Provider>
            </HashRouter>
        </React.StrictMode>
    </HelmetProvider>
)